<template>
  <v-row no-gutters>
    <Parent />
    <v-col class="mt-3" cols="12">
      <v-card :loading="loading">
        <v-card-title>
          <v-row align="center">
            <v-col cols="auto">{{ $t("category") }}</v-col>

            <v-spacer></v-spacer>

            <v-col cols="auto">
              <v-btn
                color="primary"
                text
                @click="
                  addNewCategoryModel = true;
                  isUpdateCategory = false;
                  resetForm(categoryForm);
                "
                class="text-capitalize font-weight-regular"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("create_new") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="px-0">
          <Table
            v-if="notificationCategory && paginationCategory"
            :disable-pagination="true"
            :headers="headers"
            :items="notificationCategory"
            :search="search"
            :footer="false"
            :number-of-pages="paginationTag.records_total"
            :total-records="paginationTag.total_pages"
            @update:options="updateCategoryTable"
            @click:row="editCategory"
          >
            <template v-slot:item.name="{ item }">
              <span>
                {{ item.name }}
              </span>
            </template>

            <template v-slot:item.created_at="{ item }">
              <span>
                {{ item.created_at }}
              </span>
            </template>

            <template v-slot:item.posts_count="{ item }">
              <span>
                {{ item.posts_count }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon color="secondary" @click="editCategory(item)">
                <v-icon size="12">$edit</v-icon>
              </v-btn>
              <v-icon
                @click.stop="
                  deleteItem = item;
                  deleteItemDlg = true;
                  isDeleteTag = false;
                "
              >
                mdi-delete
              </v-icon>
            </template>
          </Table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card :loading="loading">
        <v-card-title>
          <v-row align="center">
            <v-col cols="auto">{{ $t("tag") }}</v-col>

            <v-spacer></v-spacer>

            <v-col cols="auto">
              <v-btn
                color="primary"
                text
                @click="
                  addNewTagModel = true;
                  isUpdateTag = false;
                  resetForm(tagForm);
                "
                class="text-capitalize font-weight-regular"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("create_new") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-0">
          <Table
            v-if="notificationTags && paginationTag"
            :headers="headersTags"
            :items="notificationTags"
            :search="search"
            :number-of-pages="paginationTag.records_total"
            :total-records="paginationTag.total_pages"
            @update:options="updateTagsTable"
            @click:row="editTag"
          >
            <template v-slot:item.name="{ item }">
              <span>
                {{ item.name }}
              </span>
            </template>

            <template v-slot:item.created_at="{ item }">
              <span>
                {{ item.created_at }}
              </span>
            </template>
            <template v-slot:item.posts_count="{ item }">
              <span>
                {{ item.posts_count }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon color="secondary" @click="editTag(item)">
                <v-icon size="12">$edit</v-icon>
              </v-btn>
              <v-icon
                @click.stop="
                  deleteItem = item;
                  deleteItemDlg = true;
                  isDeleteTag = true;
                "
              >
                mdi-delete
              </v-icon>
            </template>
          </Table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-row justify="center">
        <!-- Category Model -->
        <v-dialog
          ref="mytext"
          v-model="addNewCategoryModel"
          persistent
          width="460px"
        >
          <v-card class="pb-5">
            <v-card-title class="d-flex justify-space-between">
              <span>
                {{ isUpdateCategory ? $t("update") : $t("create_new") }}
                {{ $t("category") }}</span
              >
              <v-btn
                :disabled="formStatus"
                color="red"
                text
                @click="addNewCategoryModel = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <div ref="categoryForm">
              <table class="px-5 widthfull">
                <tbody>
                  <tr>
                    <td>
                      <v-label>{{ $t("category_name_label") }}</v-label>
                    </td>
                    <td>
                      <v-text-field
                        ref="categoryName"
                        :error-messages="errorMessages"
                        :rules="rules.categoryName"
                        v-model="categoryName"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center mt-5">
                <v-btn
                  v-if="!isUpdateCategory"
                  color="primary"
                  :loading="formStatus"
                  :disabled="formStatus"
                  @click="submitForm('category')"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  v-else-if="isUpdateCategory"
                  color="primary"
                  :disabled="formStatus"
                  :loading="formStatus"
                  @click="submitForm('update-category')"
                  >{{ $t("update_category") }}</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-dialog>

        <!-- Tag Model -->
        <v-dialog
          ref="mytext"
          v-model="addNewTagModel"
          persistent
          width="460px"
        >
          <v-card class="pb-5">
            <v-card-title class="d-flex justify-space-between">
              <span
                >{{ isUpdateCategory ? $t("update") : $t("create_new") }}
                {{ $t("tag") }}</span
              >
              <v-btn
                :disabled="formStatus"
                color="red"
                text
                @click="addNewTagModel = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <table class="px-5 widthfull" ref="tagForm">
              <tbody>
                <tr>
                  <td>
                    <v-label>{{ $t("tag_name_label") }}</v-label>
                  </td>
                  <td>
                    <v-text-field
                      ref="tagName"
                      :error-messages="errorMessages"
                      :rules="rules.tagName"
                      v-model="tagName"
                      class=""
                    >
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center mt-5">
              <v-btn
                v-if="!isUpdateTag"
                color="primary"
                :loading="formStatus"
                :disabled="formStatus"
                @click="submitForm('tag')"
              >
                {{ $t("save") }}
              </v-btn>
              <v-btn
                v-else-if="isUpdateTag"
                color="primary"
                :loading="formStatus"
                :disabled="formStatus"
                @click="submitForm('update-tag')"
              >
                {{ $t("update_tag") }}
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="500"
      v-model="deleteItemDlg"
    >
      <v-card>
        <v-toolbar color="primary" dark>{{ $t("confirmation") }}</v-toolbar>
        <v-card-text>
          <v-row class="text-h7 pa-3 pt-7">
            {{ $t("page_category_tags_confirmation_message") }}
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="formStatus"
            color="primary"
            class="mr-3"
            @click="deleteItemConfirm"
          >
            {{ $t("confirm") }}
          </v-btn>
          <v-btn
            :disabled="formStatus"
            color="red"
            dark
            @click="deleteItemDlg = false"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Parent from "@/views/admin/Notification/index";
import Table from "@/components/admin/partials/Table/Table";

export default {
  name: "index",
  components: { Table, Parent },
  created() {
    this.getCategoryDataFromApi();
    this.getTagDataFromApi();
  },
  computed: {
    notificationCategory: function() {
      return this.$store.getters.allCategories;
    },
    paginationCategory: function() {
      return this.$store.getters.categoryPagination;
    },
    singleCategory: function() {
      return this.$store.getters.singleCategory;
    },

    notificationTags: function() {
      return this.$store.getters.allTags;
    },
    paginationTag: function() {
      return this.$store.getters.tagPagination;
    },
    categoryForm() {
      return {
        categoryName: this.categoryName
      };
    },
    tagForm() {
      return {
        tagName: this.tagName
      };
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("page_category_table_header_category"),
          align: "center",
          value: "name"
        },
        {
          text: this.$t("page_category_table_header_related_posts"),
          align: "center",
          value: "notifications_count"
        },
        {
          text: this.$t("page_category_table_header_created"),
          align: "center",
          value: "created_at"
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false
        }
      ],
      headersTags: [
        {
          text: this.$t("page_tag_table_header_tag"),
          align: "center",
          value: "name"
        },
        // { text: "Related Posts", align: "center", value: "posts_count" },
        {
          text: this.$t("page_tag_table_header_created"),
          align: "center",
          value: "created_at"
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false
        }
      ],
      search: "",
      boolTrue: true,
      addNewCategoryModel: false,
      addNewTagModel: false,
      tagName: "",
      categoryName: "",
      formHasErrors: false,
      errorMessages: "",
      loading: false,
      isUpdateCategory: false,
      isUpdateCategoryID: false,
      isUpdateTag: false,
      isUpdateTagID: false,
      rules: {
        tagName: [value => !!value || this.$t("tag_rule")],
        categoryName: [value => !!value || this.$t("category_rule")]
      },
      deleteItem: null,
      deleteItemDlg: false,
      isDeleteTag: false,
      formStatus: false,
      initialCategoryLoad: true,
      initialTagLoad: true
    };
  },
  methods: {
    submitForm(scope) {
      if (scope === "category") {
        this.validateCategoryForm();
      } else if (scope === "tag") {
        this.validateTagForm();
      } else if (scope === "update-tag") {
        this.validateTagForm(true);
      } else if (scope === "update-category") {
        this.validateCategoryForm(true);
      }
    },

    validateCategoryForm(isUpdate = false) {
      this.formHasErrors = false;
      Object.keys(this.categoryForm).forEach(f => {
        if (!this.categoryForm[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        this.saveNotificationCategories(isUpdate);
      }
    },

    validateTagForm(isUpdate = false) {
      this.formHasErrors = false;
      Object.keys(this.tagForm).forEach(f => {
        if (!this.tagForm[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        this.saveNotificationTags(isUpdate);
      }
    },

    updateCategoryTable(e) {
      if (!this.initialCategoryLoad) this.getCategoryDataFromApi(e);
    },

    updateTagsTable(e) {
      if (!this.initialTagLoad) this.getTagDataFromApi(e);
    },

    async getCategoryDataFromApi(e) {
      this.loading = true;
      let data = {};
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        data.order = e.sortDesc[0] ? "desc" : "asc";
        data.sort = e.sortBy[0];
      } else {
        data.sort = "id";
        data.order = "desc";
      }
      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        data.page = e.page;
        data.paginate = e.itemsPerPage;
      }
      await this.$store.dispatch("GET_ALL_CATEGORIES", data);
      this.initialCategoryLoad = false;
      this.loading = false;
    },

    async getTagDataFromApi(e) {
      this.loading = true;
      let data = {};
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        data.order = e.sortDesc[0] ? "desc" : "asc";
        data.sort = e.sortBy[0];
      } else {
        data.sort = "id";
        data.order = "desc";
      }
      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        data.page = e.page;
        data.paginate = e.itemsPerPage;
      }
      await this.$store.dispatch("GET_ALL_TAGS", data);
      this.initialTagLoad = false;
      this.loading = false;
    },

    saveNotificationTags(isUpdate = false) {
      this.formStatus = true;
      let postData = {
        name: this.tagName
      };
      if (isUpdate) {
        postData.id = this.isUpdateTagID;
        this.$store
          .dispatch("EDIT_TAG", postData)
          .then(result => {
            if (result.status === 200) {
              // this.$refs.observer_tag.reset()
              this.addNewTagModel = false;
              this.getTagDataFromApi();
            }
            this.formStatus = false;
          })
          .catch(error => {
            this.formStatus = false;
            this.$store.dispatch("ALERT", {
              show: true,
              text: error.data.error.errors.name
            });
          });
      } else {
        this.$store
          .dispatch("ADD_TAG", postData)
          .then(result => {
            if (result.status === 200) {
              // this.$refs.observer_tag.reset()
              this.addNewTagModel = false;
              this.getTagDataFromApi();
            }
            this.formStatus = false;
          })
          .catch(error => {
            this.formStatus = false;
            this.$store.dispatch("ALERT", {
              show: true,
              text: error.data.error.errors.name
            });
          });
      }
    },

    saveNotificationCategories(isUpdate = false) {
      this.formStatus = true;
      let postData = {
        name: this.categoryName
      };
      if (isUpdate) {
        postData.id = this.isUpdateCategoryID;
        this.$store
          .dispatch("EDIT_CATEGORY", postData)
          .then(result => {
            if (result.status === 200) {
              // this.$refs.observer_category.reset()
              this.addNewCategoryModel = false;
              this.getCategoryDataFromApi();
            }
            this.formStatus = false;
          })
          .catch(error => {
            this.formStatus = false;
            this.$store.dispatch("ALERT", {
              show: true,
              text: error.data.error.errors.name
            });
          });
      } else {
        this.$store
          .dispatch("ADD_CATEGORY", postData)
          .then(result => {
            if (result.status === 200) {
              // this.$refs.observer_category.reset()
              this.addNewCategoryModel = false;
              this.getCategoryDataFromApi();
            }
            this.formStatus = false;
          })
          .catch(error => {
            this.formStatus = false;
            this.$store.dispatch("ALERT", {
              show: true,
              text: error.data.error.errors.name
            });
          });
      }
    },

    editCategory(item) {
      this.isUpdateCategory = true;
      this.addNewCategoryModel = true;
      this.categoryName = item.name;
      this.isUpdateCategoryID = item.id;
    },

    editTag(data) {
      this.isUpdateTag = true;
      this.addNewTagModel = true;
      this.tagName = data.name;
      this.isUpdateTagID = data.id;
    },

    deleteItemConfirm() {
      this.deleteItemDlg = false;
      this.formStatus = true;
      if (this.isDeleteTag) {
        this.$store.dispatch("DELETE_TAG", this.deleteItem).then(() => {
          this.notificationTags.splice(
            this.notificationTags.indexOf(this.deleteItem),
            1
          );

          this.formStatus = false;
        });
      } else if (!this.isDeleteTag) {
        this.$store.dispatch("DELETE_CATEGORY", this.deleteItem).then(() => {
          this.notificationCategory.splice(
            this.notificationCategory.indexOf(this.deleteItem),
            1
          );

          this.formStatus = false;
        });
      }
    },

    resetForm(thisFormName) {
      this.errorMessages = [];
      this.formHasErrors = false;
      Object.keys(thisFormName).forEach(f => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
