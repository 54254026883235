var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('Parent'), _c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.$t("category")))]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.addNewCategoryModel = true;
        _vm.isUpdateCategory = false;

        _vm.resetForm(_vm.categoryForm);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("create_new")) + " ")], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_vm.notificationCategory && _vm.paginationCategory ? _c('Table', {
    attrs: {
      "disable-pagination": true,
      "headers": _vm.headers,
      "items": _vm.notificationCategory,
      "search": _vm.search,
      "footer": false,
      "number-of-pages": _vm.paginationTag.records_total,
      "total-records": _vm.paginationTag.total_pages
    },
    on: {
      "update:options": _vm.updateCategoryTable,
      "click:row": _vm.editCategory
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.created_at) + " ")])];
      }
    }, {
      key: "item.posts_count",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.posts_count) + " ")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.editCategory(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "12"
          }
        }, [_vm._v("$edit")])], 1), _c('v-icon', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.deleteItem = item;
              _vm.deleteItemDlg = true;
              _vm.isDeleteTag = false;
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, false, 4044834244)
  }) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(_vm._s(_vm.$t("tag")))]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.addNewTagModel = true;
        _vm.isUpdateTag = false;

        _vm.resetForm(_vm.tagForm);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("create_new")) + " ")], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_vm.notificationTags && _vm.paginationTag ? _c('Table', {
    attrs: {
      "headers": _vm.headersTags,
      "items": _vm.notificationTags,
      "search": _vm.search,
      "number-of-pages": _vm.paginationTag.records_total,
      "total-records": _vm.paginationTag.total_pages
    },
    on: {
      "update:options": _vm.updateTagsTable,
      "click:row": _vm.editTag
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.created_at) + " ")])];
      }
    }, {
      key: "item.posts_count",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.posts_count) + " ")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.editTag(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "12"
          }
        }, [_vm._v("$edit")])], 1), _c('v-icon', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.deleteItem = item;
              _vm.deleteItemDlg = true;
              _vm.isDeleteTag = true;
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, false, 2041287565)
  }) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', {
    ref: "mytext",
    attrs: {
      "persistent": "",
      "width": "460px"
    },
    model: {
      value: _vm.addNewCategoryModel,
      callback: function callback($$v) {
        _vm.addNewCategoryModel = $$v;
      },
      expression: "addNewCategoryModel"
    }
  }, [_c('v-card', {
    staticClass: "pb-5"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.isUpdateCategory ? _vm.$t("update") : _vm.$t("create_new")) + " " + _vm._s(_vm.$t("category")))]), _c('v-btn', {
    attrs: {
      "disabled": _vm.formStatus,
      "color": "red",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.addNewCategoryModel = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('div', {
    ref: "categoryForm"
  }, [_c('table', {
    staticClass: "px-5 widthfull"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('v-label', [_vm._v(_vm._s(_vm.$t("category_name_label")))])], 1), _c('td', [_c('v-text-field', {
    ref: "categoryName",
    attrs: {
      "error-messages": _vm.errorMessages,
      "rules": _vm.rules.categoryName
    },
    model: {
      value: _vm.categoryName,
      callback: function callback($$v) {
        _vm.categoryName = $$v;
      },
      expression: "categoryName"
    }
  })], 1)])])]), _c('div', {
    staticClass: "text-center mt-5"
  }, [!_vm.isUpdateCategory ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.formStatus,
      "disabled": _vm.formStatus
    },
    on: {
      "click": function click($event) {
        return _vm.submitForm('category');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save")))]) : _vm.isUpdateCategory ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus
    },
    on: {
      "click": function click($event) {
        return _vm.submitForm('update-category');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("update_category")))]) : _vm._e()], 1)])], 1)], 1), _c('v-dialog', {
    ref: "mytext",
    attrs: {
      "persistent": "",
      "width": "460px"
    },
    model: {
      value: _vm.addNewTagModel,
      callback: function callback($$v) {
        _vm.addNewTagModel = $$v;
      },
      expression: "addNewTagModel"
    }
  }, [_c('v-card', {
    staticClass: "pb-5"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.isUpdateCategory ? _vm.$t("update") : _vm.$t("create_new")) + " " + _vm._s(_vm.$t("tag")))]), _c('v-btn', {
    attrs: {
      "disabled": _vm.formStatus,
      "color": "red",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.addNewTagModel = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('table', {
    ref: "tagForm",
    staticClass: "px-5 widthfull"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('v-label', [_vm._v(_vm._s(_vm.$t("tag_name_label")))])], 1), _c('td', [_c('v-text-field', {
    ref: "tagName",
    attrs: {
      "error-messages": _vm.errorMessages,
      "rules": _vm.rules.tagName
    },
    model: {
      value: _vm.tagName,
      callback: function callback($$v) {
        _vm.tagName = $$v;
      },
      expression: "tagName"
    }
  })], 1)])])]), _c('div', {
    staticClass: "text-center mt-5"
  }, [!_vm.isUpdateTag ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.formStatus,
      "disabled": _vm.formStatus
    },
    on: {
      "click": function click($event) {
        return _vm.submitForm('tag');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]) : _vm.isUpdateTag ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.formStatus,
      "disabled": _vm.formStatus
    },
    on: {
      "click": function click($event) {
        return _vm.submitForm('update-tag');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("update_tag")) + " ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition",
      "max-width": "500"
    },
    model: {
      value: _vm.deleteItemDlg,
      callback: function callback($$v) {
        _vm.deleteItemDlg = $$v;
      },
      expression: "deleteItemDlg"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("confirmation")))]), _c('v-card-text', [_c('v-row', {
    staticClass: "text-h7 pa-3 pt-7"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_category_tags_confirmation_message")) + " ")])], 1), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "disabled": _vm.formStatus,
      "color": "primary"
    },
    on: {
      "click": _vm.deleteItemConfirm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.formStatus,
      "color": "red",
      "dark": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteItemDlg = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }